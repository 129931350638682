/* {"Timberwolf":"cfdbd5",
	"Alabaster":"e8eddf",
	"Flame":"e4572e",
	"Eerie Black":"242423",
	"Jet":"333533"} */

:root {
	--bright: #cfdbd5;
	--text-bright: #e8eddf;
	/* --contrast: #e4572e; */
	--contrast: #2e8ce4;
	--dark: #242423;
	--dark-sec: #333533;
	--bright-o: #060707b0;
	--text-bright-o: #e8eddfab;
	/* --contrast-o: #e4582eab; */
	--contrast-o: #2e8ce4c5;
	--dark-o: #242423b4;
	--dark-sec-o: #333533c0;
	--shadow: 2px 1px 5px 0px #adadad;
	--shadow2: 0px 1px 5px 0px #7ab2e6ad;
	--gradient-dark: linear-gradient(to bottom right, #242423, #585858);
	--gradient-fallback: #232526;
	--gradient-bg: linear-gradient(to bottom, #000000, #191b1b);
	--gradient-bg-webkit: -webkit-linear-gradient(to bottom, #000000, #191b1b);
	/* ! breakpoints */
	--mobile: 480px;
	--tablet: 768px;
	--laptop: 1024px;
	overflow-x: hidden;
}
* {
	margin: 0;
	box-sizing: border-box;
	font-family: "Kanit", sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
strong,
a,
li {
	color: var(--text-bright);
	line-height: 1.5;
	text-align: left;
}
